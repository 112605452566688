/*
 * Get Viewport Dimensions
 * returns object with viewport dimensions to match css in width and height properties
 * ( source: http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript )
*/
function updateViewportDimensions() {
	var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
	return { width:x,height:y };
}
// setting the viewport width
var viewport = updateViewportDimensions();


/*
 * Throttle Resize-triggered Events
 * Wrap your actions in this function to throttle the frequency of firing them off, for better performance, esp. on mobile.
 * ( source: http://stackoverflow.com/questions/2854407/javascript-jquery-window-resize-how-to-fire-after-the-resize-is-completed )
*/
var waitForFinalEvent = (function () {
	var timers = {};
	return function (callback, ms, uniqueId) {
		if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
		if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
		timers[uniqueId] = setTimeout(callback, ms);
	};
})();

// how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
var timeToWaitForLast = 100;


/*
 * Here's an example so you can see how we're using the above function
 *
 * This is commented out so it won't work, but you can copy it and
 * remove the comments.
 *
 *
 *
 * If we want to only do it on a certain page, we can setup checks so we do it
 * as efficient as possible.
 *
 * if( typeof is_home === "undefined" ) var is_home = $('body').hasClass('home');
 *
 * This once checks to see if you're on the home page based on the body class
 * We can then use that check to perform actions on the home page only
 *
 * When the window is resized, we perform this function
 * $(window).resize(function () {
 *
 *    // if we're on the home page, we wait the set amount (in function above) then fire the function
 *    if( is_home ) { waitForFinalEvent( function() {
 *
 *	// update the viewport, in case the window size has changed
 *	viewport = updateViewportDimensions();
 *
 *      // if we're above or equal to 768 fire this off
 *      if( viewport.width >= 768 ) {
 *        console.log('On home page and window sized to 768 width or more.');
 *      } else {
 *        // otherwise, let's do this instead
 *        console.log('Not on home page, or window sized to less than 768.');
 *      }
 *
 *    }, timeToWaitForLast, "your-function-identifier-string"); }
 * });
 *
 * Pretty cool huh? You can create functions like this to conditionally load
 * content and other stuff dependent on the viewport.
 * Remember that mobile devices and javascript aren't the best of friends.
 * Keep it light and always make sure the larger viewports are doing the heavy lifting.
 *
*/


/*
 * Put all your regular jQuery in here.
*/
jQuery(document).ready(function($) {

  //STICKY MENU
    // init controller
    var controller = new ScrollMagic.Controller();

    $(window).on("resize", function(e) {
      if ($(window).width() < 1024 && controller.enabled()) {
        controller.enabled(false);
      } else if (!controller.enabled()) {
        controller.enabled(true);
      }
      controller.update(true);
    });

    // create a scene
    var sceneStickyMenu = new ScrollMagic.Scene({
        triggerElement: '#inner-header',
        triggerHook: 0
        //duration: 300    // the scene should last for a scroll distance of 100px
        //offset: 50    // start this scene after scrolling for 50px
    })
    .setClassToggle('#inner-header', 'animated')
    .setPin('#inner-header') // pins the element for the the scene's duration
    //.addIndicators({name: 'Sticky menu'})
    .addTo(controller); // assign the scene to the controller
    
  //ADDING CLASS FOR SUBMENU HOVER 
    $('.top-nav .menu-item-has-children').hover(function(e){
      $('#inner-header').addClass('animated-bg');
      $('#container').addClass('active');
    }, function() {
      $('#inner-header').removeClass('animated-bg');
      $('#container').removeClass('active');
    });
  //COPY IMGS LIST IN HEADER
    $('.produits .img-menu ul').html($('.img-menu-fake').html());

    $('.produits .sub-menu a').hover(function(){
      var attrImg = $(this).data('postid');
      console.log(attrImg);
      $('.produits .img-menu li').removeClass('active');
      $('.produits .img-menu li[data-postid='+attrImg+']').addClass('active');
    }, function(){
    });
  //SLIDER HOMEPAGE HEADER
    $('.slides-header').slick({
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      appendDots: '.dots-container'
    });
  //SLIDER PAGES HEADER
    $('#slider-header').slick({
      arrows: false,
      dots: true,
      fade: true,
      autoplay: true,
      appendDots: '.dots-container'
    });
  //ADDING SPAN FOR ® HOME TITLE
    var mystring = $('.title-home h1 span:first-child').text();
    var mystringLength = mystring.length;
    var lastcar = mystring.substring(0, mystringLength-1) + "<span>" + mystring.substring(mystringLength-1) + "</span>";
    $('.title-home h1 span:first-child').html(lastcar);
  //HOVER PRODUCTS HOME
    $('.product-nav li').hover(function(){
      var attrImg = $(this).attr('class');
      $('.product-nav li').removeClass('active');
      $(this).addClass('active');
      $('.product-visu li').removeClass('active');
      $('.product-visu li.'+attrImg).addClass('active');
    }, function(){
    });
  //SLIDER REALISATIONS HOME
    $('.slider-realisation').slick({
      arrows: false,
      dots: false,
      variableWidth: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
      asNavFor: '.slider-txt-realisaton'
    });
  //SLIDER TXT REALISATIONS HOME
    $('.slider-txt-realisaton').slick({
      arrows: true,
      dots: true,
      slidesToShow: 1,
      fade: true,
      infinite: true,
      autoplay: true,
      asNavFor: '.slider-realisation',
      appendArrows: '.slider-arrows',
      appendDots: '.slider-arrows',
      nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
      prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>'
    });
  //Click event to scroll to top
    var scrollTop = $(".scrollTop");
    $(scrollTop).click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 800);
      return false;
    });
  // INCREASE NUMBER
    var sceneIncreaseNumber = new ScrollMagic.Scene({
        triggerElement: '.key-numbers',
        triggerHook:    0.8,
        reverse:        false,
        offset:         -50
    })
    .setClassToggle('.key-numbers', 'animated')
    //.addIndicators({name: 'key-numbers'})
    .addTo(controller); // assign the scene to the controller

    sceneIncreaseNumber.on("start", function (event) {
        var Cont={val:0} , 
        NewVal = $('#number_0').text();
        NewVal2 = $('#number_1').text();
        NewVal3 = $('#number_2').text();
        NewVal4 = $('#number_3').text();
        NewVal5 = $('#number_4').text();
        NewVal6 = $('#number_5').text();
        console.log(NewVal6);
        if (NewVal != "") {
          TweenLite.to(Cont,1,{val:NewVal,roundProps:"val",onUpdate:function(){
            document.getElementById("number_0").innerHTML=Cont.val;
          }});
        }
        if (NewVal2 != "") {
          TweenLite.to(Cont,1,{val:NewVal2,roundProps:"val",onUpdate:function(){
            document.getElementById("number_1").innerHTML=Cont.val;
          }});
        }
        if (NewVal3 != "") {
          TweenLite.to(Cont,1,{val:NewVal3,roundProps:"val",onUpdate:function(){
            document.getElementById("number_2").innerHTML=Cont.val;
          }});
        }
        if (NewVal4 != "") {
          TweenLite.to(Cont,1,{val:NewVal4,roundProps:"val",onUpdate:function(){
            document.getElementById("number_3").innerHTML=Cont.val;
          }});
        }
        if (NewVal5 != "") {
          TweenLite.to(Cont,1,{val:NewVal5,roundProps:"val",onUpdate:function(){
            document.getElementById("number_4").innerHTML=Cont.val;
          }});
        }
        if (NewVal6 != "") {
          TweenLite.to(Cont,1,{val:NewVal6,roundProps:"val",onUpdate:function(){
            document.getElementById("number_5").innerHTML=Cont.val;
          }});
        }
    });

  // OPEN/CLOSE FILTERS

  var $output = $('#output ul');

  $('.open-filters').on('click', function(e){
    $(this).toggleClass('active');
    $('#filters-realisations').slideToggle();
    $output.toggleClass('active');
  });

  // ISOTOPE REALISATIONS
  var $gridReal = $('#grid-realisations').imagesLoaded( function() {
    $gridReal.isotope({
      // options
      itemSelector: '.grid-item',
      layoutMode: 'masonry',
      percentPosition: true,
      masonry: {
        // use element for option
        // columnWidth: '.grid-sizer',
        // gutter: '.gutter-sizer'
      }
    });
  });
  var $checkboxes = $('#filters-realisations input');
  $checkboxes.change( function() {
    // map input values to an array
    var inclusives = [];
    var boxName = [];
    // inclusive filters from checkboxes
    $checkboxes.each( function( i, elem ) {
      // if checkbox, use value if checked
      if ( elem.checked ) {
        inclusives.push( elem.value );
        boxName.push('<li>'+$(elem).data('namebox')+'</li>');
        console.log(elem);
      }
    });
    
    // combine inclusive filters
    var filterValue = inclusives.length ? inclusives.join('') : '*';
    $output.html(boxName);
    $gridReal.isotope({ filter: filterValue });

    // display message box if no filtered items
    if ( !$gridReal.data('isotope').filteredItems.length ) {
      $('#msg-box').show();
    } else {
      $('#msg-box').hide();
    }

  });
  // FANCYBOX SINGLE REALISATION
  $('[data-fancybox="gallery"]').fancybox({
    toolbar: true,
    smallBtn: false,
    buttons: [
      //"zoom",
      //"share",
      //"slideShow",
      //"fullScreen",
      "download",
      //"thumbs",
      "close"
    ],
    btnTpl: {
      // Arrows
      
      arrowLeft:
        '<button data-fancybox-prev class="fancybox-button fancybox-arrow fancybox-button--arrow_left" title="{{PREV}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></div>' +
        "</button>",

      arrowRight:
        '<button data-fancybox-next class="fancybox-button fancybox-arrow fancybox-button--arrow_right" title="{{NEXT}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></div>' +
        "</button>",
    },
  });
  $('[data-fancybox="gallery-mobile"]').fancybox({
    toolbar: true,
    smallBtn: false,
    buttons: [
      //"zoom",
      //"share",
      //"slideShow",
      //"fullScreen",
      "download",
      //"thumbs",
      "close"
    ],
    btnTpl: {
      // Arrows
      
      arrowLeft:
        '<button data-fancybox-prev class="fancybox-button fancybox-arrow fancybox-button--arrow_left" title="{{PREV}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></div>' +
        "</button>",

      arrowRight:
        '<button data-fancybox-next class="fancybox-button fancybox-arrow fancybox-button--arrow_right" title="{{NEXT}}">' +
        '<div><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></div>' +
        "</button>",
    },
  });
  $('#slider-realMobile').slick({
    arrows: false,
    dots: true,
    slidesToShow: 1,
    infinite: false,
    autoplay: true,
    appendDots: '.dots-slider-realMobile',
  });
  // SLIDER RESSOURCES
  $('#slider-ressources').slick({
    arrows: false,
    dots: true,
    slidesToShow: 2,
    infinite: false,
    autoplay: true,
    appendDots: '.dots-container-ressources',
    responsive: [
        {
            breakpoint: 760, // mobile breakpoint
            settings: {
                slidesToShow: 1
            }
        }
    ]
  });
  // SLIDER PRODUCTS
  $('.slider-products').slick({
    arrows: true,
    dots: true,
    variableWidth: true,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    appendArrows: '.slider-arrows',
    appendDots: '.slider-arrows',
    nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
    prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
    responsive: [
        {
            breakpoint: 1024, // mobile breakpoint
            settings: {
                variableWidth: false,
            }
        }
    ]
  });
  // FANCYBOX POPUP
  $('.btn-popUP[data-fancybox]').fancybox({
    btnTpl: {
      smallBtn:
      '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
      '<svg xmlns="http://www.w3.org/2000/svg" width="13.116" height="13.117" viewBox="0 0 13.116 13.117"><path id="Tracé_1578" d="M13.129 2.143L11 .01 6.572 4.435 2.146.009.013 2.143l4.426 4.425-4.426 4.424 2.133 2.133L6.572 8.7 11 13.125l2.133-2.133L8.7 6.568z" class="cls-1" data-name="Tracé 1578" transform="translate(-.013 -.009)"/></svg>' +
      '</button>'
    }
  });
  // INFORMATIONS ARRAY
  $('.tab-infos .year').on('click', function(e){
    var year = $(this).html();
    $('.tab-infos .year').removeClass('active');
    $(this).addClass('active');
    $('.categories').removeClass('active');
    $('.categories[data-year='+year+']').addClass('active');
    //console.log(year);
  });
  $('.categories li').on('click', function(e){
    var cat = $(this).data('cat');
    var yearParent = $(this).closest('.categories').data('year');

    $('.categories[data-year='+yearParent+'] li').removeClass('active');
    $(this).addClass('active');
    $('.categories div[data-cat]').removeClass('active');
    $('.categories div[data-cat='+cat+']').addClass('active');
    //console.log(year);
  });
  //SLIDERS TEAM PAGE QUI SOMMES NOUS
  $('.slider-img-team').slick({
    arrows: false,
    dots: false,
    variableWidth: false,
    fade: true,
    slidesToShow: 1,
    infinite: true,
    autoplay: true,
    asNavFor: '.slider-txt-team'
  });

  $('.slider-txt-team').slick({
    arrows: true,
    dots: true,
    slidesToShow: 1,
    fade: true,
    infinite: true,
    autoplay: true,
    asNavFor: '.slider-img-team',
    appendArrows: '.slider-arrows-team',
    appendDots: '.slider-arrows-team',
    nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
    prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>'
  });
  //SLIDER PARTENAIRES
  $('.slider-partenaires').slick({
    arrows: true,
    dots: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    fade: false,
    infinite: true,
    autoplay: true,
    appendArrows: '.slider-arrows-partenaires',
    appendDots: '.slider-arrows-partenaires',
    nextArrow: '<button class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
    prevArrow: '<button class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="16.041" height="16.041" viewBox="0 0 16.041 16.041"><path id="Tracé_1464" d="M0 7.018h12.231L6.617 1.4 8.021 0l8.021 8.021-8.021 8.02-1.4-1.4 5.614-5.614H0z" class="cls-1" data-name="Tracé 1464"/></svg></button>',
    responsive: [
      {
        breakpoint: 760, // mobile breakpoint
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
      }
    ]
  });
  // ISOTOPE PUBLICATIONS
  var $gridPubli = $('#grid-publi').imagesLoaded( function() {
    $gridPubli.isotope({
      // options
      itemSelector: '.grid-item',
      layoutMode: 'masonry',
      percentPosition: true,
      masonry: {
        // use element for option
        columnWidth: '.grid-sizer',
        gutter: '.gutter-sizer',
      }
    });
  });
  // bind filter button click
  $('.filters-button-group').on( 'click', 'button', function() {
    var filterValue = $( this ).attr('data-filter');
    $gridPubli.isotope({ filter: filterValue });
  });
  // change is-checked class on buttons
  $('.button-group').each( function( i, buttonGroup ) {
    var $buttonGroup = $( buttonGroup );
    $buttonGroup.on( 'click', 'button', function() {
      $buttonGroup.find('.is-checked').removeClass('is-checked');
      $( this ).addClass('is-checked');
    });
  });

  // NAV MOBILE 
  var menuMobile = document.querySelector('.menuMobile');
  function addClassMenuMobile(){
    this.classList.toggle("clickMenuEight");
    $('.menu-wrapper').toggleClass("active");
  }
  menuMobile.addEventListener('click', addClassMenuMobile);

  $('.header-mobile .menu-item-has-children > a').on('click', function(e){
    e.preventDefault();
    $(this).next('ul').slideToggle(400);
    $(this).parent().toggleClass('active');
  });

  // INPUT TYPE FILES

  var inputs = $('.ginput_container_fileupload input[type="file"]');
  inputs.each( function() {
    var label  = $(this).parents('.fichier').children('label');
    var labelVal = label.text();

    $(this).change( function() {
      var fileName = $(this).val().split("\\").pop();
      console.log(label);
      if( fileName ) {
        label.html(fileName);
      }
      else {
        label.html(labelVal);
      }
    });
  });

}); /* end of as page load scripts */
